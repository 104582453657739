// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/public/img/loading-background.gif", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#loading{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;

    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

div.loading-content{
    position: fixed;
    width: 968px;
    height: 708px;

    right: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
    text-align: center;
}

img.loading-content{
    width: 300px;
    height: 300px;
}

p.loading-content{
    color: white;
    font-size: 40px;
    font-family: "pixel";
    position: relative;
    top: -50px;
}`, "",{"version":3,"sources":["webpack://./src/components/component_css/loading.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,WAAW;IACX,YAAY;IACZ,QAAQ;IACR,SAAS;;IAET,yDAA2D;IAC3D,sBAAsB;IACtB,2BAA2B;IAC3B,4BAA4B;AAChC;;AAEA;IACI,eAAe;IACf,YAAY;IACZ,aAAa;;IAEb,UAAU;IACV,SAAS;IACT,gCAAgC;IAChC,QAAQ;IACR,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,eAAe;IACf,oBAAoB;IACpB,kBAAkB;IAClB,UAAU;AACd","sourcesContent":["#loading{\n    position: fixed;\n    width: 100%;\n    height: 100%;\n    top: 0px;\n    left: 0px;\n\n    background-image: url(\"/public/img/loading-background.gif\");\n    background-size: cover;\n    background-position: center;\n    background-repeat: no-repeat;\n}\n\ndiv.loading-content{\n    position: fixed;\n    width: 968px;\n    height: 708px;\n\n    right: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    top: 50%;\n    text-align: center;\n}\n\nimg.loading-content{\n    width: 300px;\n    height: 300px;\n}\n\np.loading-content{\n    color: white;\n    font-size: 40px;\n    font-family: \"pixel\";\n    position: relative;\n    top: -50px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
