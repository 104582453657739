import { useNavigate } from "react-router-dom";
import "./css/home.css"
import { useEffect, useRef, useState } from "react";

const Home = () =>{
    /**
     * BeforeThis:
     * start hosting webpage
     * 
     * DoThis:
     * make 404 page
     * under construction page
     * change time thingy for a VISITOR NUMBER # feature
     * Give it an icon
     * change coursor
     */ 

    // //WS CODE START
    // const socket = useRef(null) 

    // useEffect(() => {
    //     socket.current = new WebSocket('wss://enten.website/ws/get/64')
    //     socket.current.onopen = () =>{
    //         console.log("Websocket connected")
    //     }
    //     socket.current.onclose = (event) =>{
    //         console.log("webscoekt disconnected")
    //     }

        
    // }, [])

    // useEffect(() => {
    //     if(socket.current){
    //         socket.current.onmessage = async (event) =>{
    //             console.log("message:")
    //             const message = await JSON.parse(event.data)
    //             setIsOnline(message.status)
    //         }
    //     }

    //     return () =>{
    //         if(socket.current){
    //             socket.current.onmessage = null
    //         }
    //     }
        
    // }, [socket])

    // const sendMessage = () =>{
    //     socket.current.send("buenas")
    // }
    

    //WS CODE END
    const navigate = useNavigate()
    //  return Math.random() * (max - min) + min;
    const [randomNumber, setRandomNumber] = useState(Math.floor(Math.random() * (10 - 1) + 1))
    const [date, setDate] = useState(new Date())
    const [time, setTime] = useState(date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }))
    const [isOnline, setIsOnline] = useState(false)


    const changeURL = (url) =>{
        navigate(`/${url}`)
    }


    useEffect(() => {
        setTime(date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }))
    }, [time])

    const angelMessage = () =>{
        switch (randomNumber) {
            case 1:
                alert("Your Angels Said: Get off the computer")
                break;
            case 2:
                alert("Your Angels Said: POTASSIUM")
                break;
            case 3:
                alert("Your Angels Said: THEY'RE WAITING FOR U. GO TALK TO THEM")
                break;
            case 4:
                alert("Your Angels Said: This whole page wasn't coded. ITS POWERED BY THE HOLY SPIRIT AND LAIN")
                break;
            case 5:
                alert("Your Angels Said: Arroz con habichuelas")
                break;
            case 6:
                alert("Your Angels Said: maddie is top tier humanity")
                break;
            case 7:
                alert("Your Angels Said: i miss life before the colonizers came")
                break;
            case 8:
                alert("Your Angels Said: drop the bombs!!")
                break;
            case 9:
                alert("Your Angels Said: OOO MAN U DONT WANNA SEE THIS PAGE'S CSS")
                break;
            case 10:
                alert("Your Angels Said: LET'S ALL LOVE LAIN")
                break;
        }
    }

    const AmIOnline = () =>{
        if(isOnline){
            return(
                <>
                    <p className="home" style={{position: "absolute", left: "0%", top: "88px", fontSize: "16px"}}>
                        <u>ONLINE - This user is currently connected to the world wide web and having so much fun</u>
                    </p>
                    <img style={{width: "160px", height: "120px", position: "absolute", top: "190px"}} src="/img/dance_time.gif"></img>
                </>
            )
        }
        else{
            return(
                <>
                    <p className="home" style={{position: "absolute", left: "0%", top: "88px", fontSize: "16px"}}>
                        <u>OFFLINE - This user is currently being forced to use their body to do irl side missions</u>
                        <img style={{width: "160px", height: "120px", position: "absolute", top: "80px", left: "0px"}} src="/img/cat_pc.jpg"></img>
                    </p>
                </>
            )
        }

    }

    return(
        <>
            <div>
                <div className="home">
                    <h2 className="home">Home</h2>
                    <h3 className="home"> <u>Introduction</u> </h3>                

                    <img src="/img/me.jpg" className="home"></img>
                    <p className="home">
                        Name: ENTEN, Angel, papi
                        <br></br>
                        Age: 00010010yo
                        <br></br>
                        Gender: C++ 
                        <br></br>
                        Nationality: I use arch btw
                        <br></br>
                        Iconic Phrase: "ACTUALLY..."
                        <br></br>
                        {"<"}= THIS IS ME BTW REAL
                        <br></br>
                        <u onClick={() => {changeURL("me")}} style={{cursor:"pointer"}}> MORE ABOUT ME</u> 
                    </p>

                    <img id="angel_message" src="/img/angel_message.jpg" onClick={angelMessage} style={{cursor: "pointer"}}></img>
                    <p className="home" style={{position: "absolute", left: "76%", top: "210px", fontSize: "16px"}}>
                        Current Date in me house:
                        <br></br>
                        {time}
                        <br></br>
                        {date.getFullYear()}/{date.getMonth() + 1}/{date.getDate()}
                        <br></br>
                        <AmIOnline></AmIOnline> 
                    </p>
                </div>

                <div className="update">
                    <h3 className="update"> <u>Update Log</u></h3>
                    <p className="update">
                        • 2024/12/09 - ADDED EPIC LOADING SCREEN. HOPEFULLY IT WORKS OMG {"(credits to chatgpt and stack overflow)"} 
                        <br></br>
                        <br></br>
                        • 2024/12/04 - I overworked myself and took couple of days to procrastinate TwT. BUT IM BACK AND YALL AINT LOSING ME ANYTIME SOON 
                        <br></br>
                        <br></br>
                        • 2024/11/20 - Started coding "Me" page. Went 6 hours coding... WHICH MEANS IM BECOMING FLUENT IN MACHINE-GLISH LETS GOO
                        <br></br>
                        <br></br>
                        • 2024/11/18 - ENTEN.WEBSITE IS FINALLY UP AND RUNNING. YALL BETTER DONT HACK IT CAUSE IMA LOSE A BALL 
                        <br></br>
                        <br></br>
                        • 2024/11/16 - Finished 404 and UnderConstruction pages. Now I can go and take a shower {"(THAT WAS A JK)"}
                        <br></br>
                        <br></br>
                        • 2009/11/15 - Finished Home Page, ate a chicken sandwhich and cried. + procrastinated
                        for 6 hours straight
                        <br></br>
                        <br></br>
                        • 2024/11/14 - Added Online/Offline feature using discord API to fetch precense. WHAT A NERD
                        <br></br>
                        ‎
                        <br></br>
                        ‎
                    </p> 
                </div>



                <div className="navigation">
                    <h2 className="navigation">Navigation</h2>
                    <p className="navigation"><u>Home</u></p>
                    <p className="navigation" style={{cursor: "pointer"}} onClick={() => {changeURL("me")}}>Me</p>
                    <p className="navigation" style={{cursor: "pointer"}} onClick={() => {changeURL("hobbies")}} >Hobbies</p>
                    <p className="navigation" style={{cursor: "pointer"}} onClick={() =>{changeURL("projects")}}>Projects</p>
                    <p className="navigation" style={{cursor: "pointer"}} onClick={() =>{changeURL("guestbook")}}>Guest Book</p>
                    <p className="navigation" style={{cursor: "pointer"}} onClick={() =>{changeURL("blog")}}>Blog</p>

                    <h3 className="navigation"><u>TODO</u></h3>
                    <p style={{position: "absolute", top: "340px", left: "35%", fontSize: "16px", color: "white", fontFamily: "sans-serif", textAlign: "left"}} className="navigation">
                        • <del>kms</del> 
                        <br></br>
                        • take a shower
                        <br></br>
                        • over take the internet
                        <br></br>
                        • stop ignoring college hw
                        <br></br>
                        • <del>Configure REST API and sockets with django</del> 
                        <br></br>
                        • Add music/media player 
                        <br></br>
                        • start Hobbies, Me, Projects, Guest Book and Blog subpages 
                    </p>

                </div>


            </div>

            <img className="banner" src="/img/banners/maddie.gif" style={{top:"6%", left: "8px"}}></img>
            <img className="banner" src="/img/banners/robot.gif" style={{top:"6%", left: "158px"}}></img>
            <img className="banner" src="/img/banners/bank.gif" style={{top:"6%", left: `${(150 * 2 + 8)}px`}}></img>
            <img className="banner" src="/img/banners/webkid.gif" style={{top:"6%", left: `${(150 * 3 + 8)}px`}}></img>
            <img className="banner" src="/img/banners/leo.gif" style={{top:"6%", left: `${(150 * 4 + 8)}px`}}></img>
            <img className="banner" src="/img/banners/coffee.gif" style={{top:"6%", left: `${(150 * 5 + 8)}px`}}></img>

            <img className="banner" src="/img/banners/autism.gif" style={{top:"92%", left: "8px"}}></img>
            <img className="banner" src="/img/banners/angelsmiley.gif" style={{top:"92%", left: "158px"}}></img>
            <img className="banner" src="/img/banners/lain.gif" style={{top:"92%", left: `${(150 * 2 + 8)}px`}}></img>
            <img className="banner" src="/img/banners/cd.gif" style={{top:"92%", left: `${(150 * 3 + 8)}px`}}></img>
            <img className="banner" src="/img/banners/scenekid.gif" style={{top:"92%", left: `${(150 * 4 + 8)}px`}}></img>
            <img className="banner" src="/img/banners/computer.gif" style={{top:"92%", left: `${(150 * 5 + 8)}px`}}></img>



        </>
    )
}

export default Home;