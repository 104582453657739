import "./css/door.css"
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from "react";

const Door = () =>{
    const [screenResolution, setScreenResolution] = useState({width: 0, height: 0});
    const navigate = useNavigate()

    useEffect(() => {
        setScreenResolution({ width: window.outerWidth, height: window.outerHeight });
        const handleResize = () => {
            setScreenResolution({ width: window.outerWidth, height: window.outerHeight });
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const goHome = () =>{
        if(screenResolution.width >= 968 && screenResolution.height >= 708){
            navigate("/home")
            return ;
        }

        alert("SURE?? If ur in iphone or dont have the enough resolution this page will suck and then ill cry")
        navigate("/home")
    }
    return(
        <div id="door" >
            <div style={{position: "relative", textAlign: "center"}}>
                <img src="/img/banners/lose_shit_ban.jpg" style={{width: "280px", height: "80px"}}></img>
                <br></br>
                <img src="/img/banners/silly_guy_ban.jpg" style={{width: "280px", height: "80px"}}></img>
            </div>
            <div style={{position: "relative", textAlign: "center"}}>
                <p className="door">
                    Dear Guest, I welcome you to Enten's Digital Place.
                    <br></br>
                    Made with the purpose of sharing myself out there
                    <br></br>
                    If currently you're not using a wide screen like a monitor or tablet,
                    <br></br>
                    please do. Im not gonna spend a hundred hours configuring responsive design
                    <br></br>
                    in painful css for phones. idk how FE devs can deal with all these bs
                    <br></br>
                    With all that said please recieve the free lain to proceed. 
                    <div style={{fontFamily: "sans-serif"}} >
                        <br></br>
                        Current Width and Height: {screenResolution.width}x{screenResolution.height}
                        <br></br>
                        Recommended Minimum Width and Height: 968x708 
                        <br></br>
                        {screenResolution.width >= 968 && screenResolution.height >= 708 ? "ur good :3" : "emm ur not ready sorry traveler"}
                    </div>
                </p>

                <img src="/img/banners/powered_by.jpg" style={{width: "120px", height: "160px"}}></img>
                <img src="/img/banners/accept_jesus_ban.jpg" style={{width: "120px", height:"160px", cursor: "pointer"}} onClick={goHome}></img>
            </div>
            
            

        </div>
    )
}

export default Door;