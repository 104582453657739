// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/public/img/door-background.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

#door{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;

    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

p.door{
    font-size: 18px;
    font-family: "nintendo";
    color:white;
    position: relative;
    text-align:center;
    top: 30%
}`, "",{"version":3,"sources":["webpack://./src/css/door.css"],"names":[],"mappings":";;AAEA;IACI,eAAe;IACf,WAAW;IACX,YAAY;IACZ,QAAQ;IACR,SAAS;;IAET,yDAAwD;IACxD,sBAAsB;IACtB,2BAA2B;IAC3B,4BAA4B;AAChC;;AAEA;IACI,eAAe;IACf,uBAAuB;IACvB,WAAW;IACX,kBAAkB;IAClB,iBAAiB;IACjB;AACJ","sourcesContent":["\n\n#door{\n    position: fixed;\n    width: 100%;\n    height: 100%;\n    top: 0px;\n    left: 0px;\n\n    background-image: url(\"/public/img/door-background.jpg\");\n    background-size: cover;\n    background-position: center;\n    background-repeat: no-repeat;\n}\n\np.door{\n    font-size: 18px;\n    font-family: \"nintendo\";\n    color:white;\n    position: relative;\n    text-align:center;\n    top: 30%\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
