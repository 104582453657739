import React from 'react';
import ReactDOM from 'react-dom/client';
import './css/index.css'; 
import "./css/fonts.css"
import App from "./App"
import Door from "./Door"
import NotFound from "./404"
import Construction from './Construction';
import Me from './Me';
import PR from './PR';
import LoadingScreen from './components/LoadingScreen';
import { LoadingProvider } from "./components/LoadingContext";
import RouteLoader from "./components/RouteLoader";
import { BrowserRouter, Route, Routes } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <LoadingProvider>
      <BrowserRouter>
        <RouteLoader>
          <Routes>
            <Route path='/' element={<Door></Door>}></Route> 
            <Route path='/home' element={<App></App>}></Route>
            <Route path='/me' element={<Me></Me>}></Route>
            <Route path='/pr' element={<PR></PR>}></Route>
            <Route path='/hobbies' element={<Construction></Construction>}></Route>
            <Route path='/projects' element={<Construction></Construction>}></Route>
            <Route path='/guestbook' element={<Construction></Construction>}></Route>
            <Route path='/blog' element={<Construction></Construction>}></Route>
            <Route path='/loading' element={<LoadingScreen></LoadingScreen>}></Route>
            <Route path='*' element={<NotFound></NotFound>}></Route>
          </Routes>
        </RouteLoader>
      </BrowserRouter>
    </LoadingProvider>
  </React.StrictMode>
);
