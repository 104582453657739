// LoadingContext.js
import React, { createContext, useContext, useState } from "react";
import LoadingScreen from "./LoadingScreen";

// Create LoadingContext
const LoadingContext = createContext();

export const useLoading = () => useContext(LoadingContext);

// Provider to wrap your app
export const LoadingProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);

  const showLoading = () => setIsLoading(true);
  const hideLoading = () => setIsLoading(false);

  return (
    <LoadingContext.Provider value={{ isLoading, showLoading, hideLoading }}>
      {children}
      {isLoading && <LoadingScreen />}
    </LoadingContext.Provider>
  );
};