// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/public/img/aero-form-final.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.window{
    position:relative;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size:contain;
    background-repeat: no-repeat;

    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width:none;
}

#resizeArea{
    position: relative;
    top: -11%;
    width: 100%;
    height: 5%;
}



#content{
    position: relative;
    top: 120px;
}
`, "",{"version":3,"sources":["webpack://./src/components/component_css/window.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,yDAAwD;IACxD,uBAAuB;IACvB,4BAA4B;;IAE5B,kBAAkB;IAClB,kBAAkB;IAClB,oBAAoB;AACxB;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,UAAU;AACd;;;;AAIA;IACI,kBAAkB;IAClB,UAAU;AACd","sourcesContent":["div.window{\n    position:relative;\n    background-image: url(\"/public/img/aero-form-final.png\");\n    background-size:contain;\n    background-repeat: no-repeat;\n\n    overflow-y: scroll;\n    overflow-x: hidden;\n    scrollbar-width:none;\n}\n\n#resizeArea{\n    position: relative;\n    top: -11%;\n    width: 100%;\n    height: 5%;\n}\n\n\n\n#content{\n    position: relative;\n    top: 120px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
