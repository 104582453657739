import "./css/app.css"
import ContentWindow from "./components/Window";
import Home from "./Home";

function App() {
  return (
    <>
      <div id="main_body">
        <div className="content">
          <ContentWindow  WindowContent={Home}></ContentWindow>
        </div>
      </div>
    </>
  );
}

export default App;
