
import { useNavigate } from "react-router-dom";
const PR = () =>{
    const navigate = useNavigate()
    return(
        <>
            <div>
                <img src="/img/pr.jpg" style={{position: "relative", textAlign: "center", cursor: "pointer"}} onClick={() =>{navigate("/me")}}></img>
            </div>
        </>
    )
}

export default PR;