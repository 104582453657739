// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#construction{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0%;
    left: 0%;
}

div.construction-content{
    position: absolute; 
    right: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;

    width: 988px;
    height: 708px;

    text-align: center;
}

img.construction-content{
    position:fixed;
    left: 0%;
}

p.construction-content{
    font-family: "pixel";
    color: black;
    position: fixed;
    
    left: 70%;
    text-align: left;
    font-size: 32px;
}`, "",{"version":3,"sources":["webpack://./src/css/construction.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,WAAW;IACX,YAAY;IACZ,OAAO;IACP,QAAQ;AACZ;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,SAAS;IACT,gCAAgC;IAChC,QAAQ;;IAER,YAAY;IACZ,aAAa;;IAEb,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,QAAQ;AACZ;;AAEA;IACI,oBAAoB;IACpB,YAAY;IACZ,eAAe;;IAEf,SAAS;IACT,gBAAgB;IAChB,eAAe;AACnB","sourcesContent":["#construction{\n    position: fixed;\n    width: 100%;\n    height: 100%;\n    top: 0%;\n    left: 0%;\n}\n\ndiv.construction-content{\n    position: absolute; \n    right: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    top: 50%;\n\n    width: 988px;\n    height: 708px;\n\n    text-align: center;\n}\n\nimg.construction-content{\n    position:fixed;\n    left: 0%;\n}\n\np.construction-content{\n    font-family: \"pixel\";\n    color: black;\n    position: fixed;\n    \n    left: 70%;\n    text-align: left;\n    font-size: 32px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
