// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/public/fonts/Nintendo.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("/public/fonts/Pixel.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("/public/fonts/Gothic.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("/public/fonts/Digital.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("/public/fonts/Starz.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("/public/fonts/Darkcastle.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "nintendo";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}

@font-face {
  font-family: "pixel";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}

@font-face {
  font-family: "gothic";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
}

@font-face {
  font-family: "digital";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_3___});
}

@font-face {
  font-family: "star";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_4___});
}


@font-face {
  font-family: "dark";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_5___});
}`, "",{"version":3,"sources":["webpack://./src/css/fonts.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,4CAAsC;AACxC;;AAEA;EACE,oBAAoB;EACpB,4CAAmC;AACrC;;AAEA;EACE,qBAAqB;EACrB,4CAAoC;AACtC;;AAEA;EACE,sBAAsB;EACtB,4CAAqC;AACvC;;AAEA;EACE,mBAAmB;EACnB,4CAAmC;AACrC;;;AAGA;EACE,mBAAmB;EACnB,4CAAwC;AAC1C","sourcesContent":["@font-face {\n  font-family: \"nintendo\";\n  src: url(\"/public/fonts/Nintendo.ttf\");\n}\n\n@font-face {\n  font-family: \"pixel\";\n  src: url(\"/public/fonts/Pixel.ttf\");\n}\n\n@font-face {\n  font-family: \"gothic\";\n  src: url(\"/public/fonts/Gothic.ttf\");\n}\n\n@font-face {\n  font-family: \"digital\";\n  src: url(\"/public/fonts/Digital.ttf\");\n}\n\n@font-face {\n  font-family: \"star\";\n  src: url(\"/public/fonts/Starz.ttf\");\n}\n\n\n@font-face {\n  font-family: \"dark\";\n  src: url(\"/public/fonts/Darkcastle.otf\");\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
