import "./component_css/window.css"
import {useEffect, useRef, useState} from "react"
import useMouse from "@react-hook/mouse-position"

const ContentWindow = ({window_width, window_height, posX, posY, windowName, windowIcon, WindowContent}) =>{
    const ref = useRef(null)
    const mouse = useMouse(ref)

    const [windowStyle, setWindowStyle] = useState({});
    const [windowSize, setWindowSize] = useState({width: window_width, height: window_height})
    const [windowPos, setWindowPos] = useState({x: posX, y: posY})
    const [windowTitle, setWindowTitle] = useState(windowName)
    const [isMouseDown, setIsMouseDown] = useState(false)


    //configures the window style
    useEffect(() =>{
        setWindowStyle({
            height: `97%`,
            width: `100%`,
            top: `${windowPos.y}px`,
            left: `${windowPos.x}px`
        });

    }, [windowPos, windowSize])

    const mouseDown = (event) => {
        event.preventDefault(); // Prevent default behavior
        window.addEventListener("mousemove", mouseMove);
        window.addEventListener("mouseup", mouseUp);
    };

    const mouseUp = () => {
        window.removeEventListener("mousemove", mouseMove);
        window.removeEventListener("mouseup", mouseUp);
    };

    const mouseMove = (event) => {
        setWindowPos((prevPos) => ({
            x: event.clientX - windowSize.width / 2, // Offset to center the window on the mouse
            y: event.clientY,
        }));
    };



    return(
        <>
            <div ref={ref} style={{width: "100%", height: "100%"}}>
                <div className='window' style={windowStyle}>
                    <p style={{fontSize: `${(windowSize.width / 100) * 3}px`, position: "relative", top: "-10px", left: "4%"}}>{windowTitle}</p>
                    <div id="resizeArea" draggable={false} >
                    <div id="content">
                        <WindowContent></WindowContent>
                    </div> 
                    </div>
                </div>
            </div>
        </>
    )
}

export default ContentWindow;