import "./component_css/scrolling.css"

const ScrollingImages = () =>{
    return(
        <>
            <div className="scroll-container">
                <div className="scroll-content">
                    <img src="/img/ic1.jpg"></img>
                    <img src="/img/ic2.jpg"></img>
                    <img src="/img/ic3.jpg"></img>
                    <img src="/img/ic4.jpg"></img>
                    <img src="/img/ic5.jpg"></img>
                    <img src="/img/ic6.jpg"></img>
                    <img src="/img/ic7.jpg"></img>
                    <img src="/img/ic8.jpg"></img>
                    <img src="/img/ic9.jpg"></img>
                    <img src="/img/ic10.jpg"></img>
                    <img src="/img/ic11.jpg"></img>
                    <img src="/img/ic12.jpg"></img>
                    <img src="/img/ic13.jpg"></img>
                    <img src="/img/ic14.jpg"></img>
                    <img src="/img/ic15.jpg"></img>
                    <img src="/img/ic16.jpg"></img>
                    <img src="/img/ic17.jpg"></img>
                    <img src="/img/ic18.jpg"></img>
                    <img src="/img/ic19.jpg"></img>
                </div>
            </div>
        </>
    )
}

export default ScrollingImages;