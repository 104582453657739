// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scroll-container {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  position:sticky;

  top: 100%;
}

.scroll-content {
  display: inline-flex;
  animation: scroll 60s linear infinite;
}

.scroll-content img {
  width: 200px;
  height: auto;
  margin-right: 10px;
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/component_css/scrolling.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,gBAAgB;EAChB,mBAAmB;EACnB,eAAe;;EAEf,SAAS;AACX;;AAEA;EACE,oBAAoB;EACpB,qCAAqC;AACvC;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE;IACE,wBAAwB;EAC1B;EACA;IACE,4BAA4B;EAC9B;AACF","sourcesContent":[".scroll-container {\n  width: 100%;\n  overflow: hidden;\n  white-space: nowrap;\n  position:sticky;\n\n  top: 100%;\n}\n\n.scroll-content {\n  display: inline-flex;\n  animation: scroll 60s linear infinite;\n}\n\n.scroll-content img {\n  width: 200px;\n  height: auto;\n  margin-right: 10px;\n}\n\n@keyframes scroll {\n  from {\n    transform: translateX(0);\n  }\n  to {\n    transform: translateX(-100%);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
