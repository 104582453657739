// RouteLoader.js
import React, { Children, useEffect } from "react";
import { useLocation, Outlet } from "react-router-dom";
import { useLoading } from "./LoadingContext";

const RouteLoader = ({children}) => {
  const { showLoading, hideLoading } = useLoading();
  const location = useLocation();

  useEffect(() => {
    showLoading();
    // Simulate a delay or data fetching for page transition
    const timer = setTimeout(() => {
      hideLoading();
    }, 1500); // Adjust the duration as needed
    return () => clearTimeout(timer);
  }, [location]);

  return (
    <>
      {children}
    </>
  )
};

export default RouteLoader;
