// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/public/img/win-xp.webp", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#main_body{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;

    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

div.content{
    position: fixed;
    width: 968px;
    height: 708px;

    right: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;

}`, "",{"version":3,"sources":["webpack://./src/css/app.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,WAAW;IACX,YAAY;IACZ,QAAQ;IACR,SAAS;;IAET,yDAAgD;IAChD,sBAAsB;IACtB,2BAA2B;IAC3B,4BAA4B;AAChC;;AAEA;IACI,eAAe;IACf,YAAY;IACZ,aAAa;;IAEb,UAAU;IACV,SAAS;IACT,gCAAgC;IAChC,QAAQ;;AAEZ","sourcesContent":["#main_body{\n    position: fixed;\n    width: 100%;\n    height: 100%;\n    top: 0px;\n    left: 0px;\n\n    background-image: url(\"/public/img/win-xp.webp\");\n    background-size: cover;\n    background-position: center;\n    background-repeat: no-repeat;\n}\n\ndiv.content{\n    position: fixed;\n    width: 968px;\n    height: 708px;\n\n    right: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    top: 50%;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
