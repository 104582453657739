import "./css/me.css"
import { useNavigate } from "react-router-dom";
import ScrollingImages from "./components/ScrollingImages";
import { useState, useEffect} from "react";

let alertUser = true
const Me = () =>{
    const navigate = useNavigate()
    const changeURL = (url) =>{
        navigate(`/${url}`)
    }


    return(
        <>
            <div id="me">
                <div className="me-content">
                    <img id="angel-banner" src="/img/angel3.jpg"></img>
                    <div className='me-navigation'>
                        <p className='me-navigation'>
                            <div style={{fontFamily: "star", position: "relative", left: "-10px"}}>U</div>
                            <div onClick={() => {changeURL("home")}}>Home</div> 
                            <div onClick={() => {changeURL("me")}}>‎ ‎ <u>Me</u></div>
                            <div onClick={() => {changeURL("hobbies")}}>‎ ‎ Hobbies</div>
                            <div onClick={() =>{changeURL("projects")}}>‎ ‎ Projects</div>
                            <div onClick={() =>{changeURL("guestbook")}}>‎ ‎ GuestBook</div>
                            <div onClick={() =>{changeURL("blog")}}>‎ ‎ Blog</div>
                            <div style={{fontFamily: "star", position: "relative", left: "10px"}}>S</div>
                        </p>
                    </div>

                    <div className="angel">
                        <p className="angel">
                            ~Angel~ 
                        </p>
                        <img src="/img/me5.png" className="angel"></img>
                        <p id="description">
                            "HOLY MOLY!!"
                            <br></br>
                            <br></br>
                            MALEEE 
                            <br></br>
                            18 YO {"(August 4th 2006)"}
                            <br></br>
                            TEXAS
                            <br></br>
                            United States
                            <br></br>
                            <u onClick={() =>{changeURL("pr")}} style={{cursor: "pointer"}}>BORN PLACE</u>
                            <br></br>
                            <br></br>
                            <div style={{fontFamily: "digital"}}>{"<"}= SEEN THIS</div>
                            <img id="angelsilver" src="/img/angelsilver.gif"></img>
                            <div style={{fontFamily: "digital"}}>‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ 
                                ‎ ‎?</div>
                        </p>
                    </div>

                    <div className="pros-cons">
                        <div id="pros-cons-title">
                            <p id="pros-cons-title-p">Pros & Cons</p>
                        </div>

                        <p className="pros-cons">
                            PROS: 
                            <br></br>
                            <div style={{fontSize: "14px"}}>
                                <br></br>
                                • I like making friends
                                <br></br>
                                • Pretty Autistic
                                <br></br>
                                • I'm always on the computer
                                <br></br>
                                • Chill af
                                <br></br>
                                • Fast learner/adapter
                                <br></br>
                                • TURRRRRRRRRRRRRRRRRRRRRon 
                            </div>
                            <div id="line"></div>
                        </p>

                        <p className="pros-cons" style={{left: "232px", top: "-148px"}}>
                            CONS:
                            <br></br>
                            <div style={{fontSize: "14px"}}>
                                <br></br>
                                • I overthink too much
                                <br></br>
                                • I care alot about what <br></br>people think
                                <br></br>
                                • I distance myself often
                                <br></br>
                                • Super bipolar
                                <br></br>
                                • I always overtalk
                            </div>
                        </p>
                    </div> 

                    <div className="thoughts">
                        <div id="thoughts-title">
                            <p id="thoughts-title-p">Random Thoughts Box</p>
                        </div>

                        <p className="thoughts">
                            • Being a human is equally beautiful and horrible
                            <br></br>
                            <br></br>
                            • I've never felt connected to something or somebody
                            <br></br>
                            <br></br>
                            • I wish I could just explore the universe. Go out there, so many things to see
                            <br></br>
                            <br></br>
                            • Follow my heart or brain? Both have been fighting for so long
                            <br></br>
                            <br></br>
                            • Is it healthy to not cry?
                            <br></br>
                            <br></br>
                            • Is God's forgiveness endless as they say...?
                            <br></br>
                            <br></br>
                            • Im kinda hungry rn ngl
                            <br></br>
                            <br></br>
                            • Life is short... is either make a legacy or go after short-term pleasures. Who knows whats after 
                            <br></br>
                            <br></br>
                            • HEAVEN IS CORRECT. HEAVEN IS ABOVE 
                        </p>
                    </div>

                    {/* <div className="forgive">
                        <img className="forgive" src="/img/wawa.jpg"></img>
                        <img className="forgive" src="/img/g2.jpg"></img>
                        <img className="forgive" src="/img/g3.jpg"></img>
                        <img className="forgive" src="/img/g4.jpg"></img>
                        <img className="forgive" src="/img/g5.jpg"></img>
                        <img className="forgive" src="/img/g6.jpg"></img>
                        <img className="forgive" src="/img/g7.jpg"></img>
                        <img className="forgive" src="/img/g8.jpg"></img>
                    </div> */}

                    <img id="waffles" src="/img/waffles.jpg"></img>

                    <p id="click">
                        <u>
                            CLICK to apologize!
                            <br></br>
                            CLICK to be saved!
                            <br></br>
                            CLICK to forgive!
                            <br></br>
                            CLICK to forget!
                            <br></br>
                            CLICK to be pure!
                            <br></br>
                            CLICK to be <u style={{fontFamily: "digital"}}>DIGITALIZED</u>
                        </u> 
                    </p>

                    <img id="angellain" src="/img/angellain.jpg"></img>

                    <div className="friends">
                        <div id="friends-title">
                            <p id="friends-title-p">FRIENDS & SPECIAL PEOPLE</p>
                        </div>

                        
                    </div>
                    <br></br>
                    <br></br>
                    <ScrollingImages></ScrollingImages>
                </div>
            </div> 
        </>
    )
}

export default Me;