import { useState, useEffect } from "react";
import "./component_css/loading.css"


const LoadingScreen = () =>{

    const [loadingDots, setLoadingDots] = useState("")

    useEffect(() => {
        const interval = setInterval(() => {
            switch (loadingDots) {
                case "":
                    setLoadingDots(".") 
                    break;
                case ".":
                    setLoadingDots("..")
                    break;
                case "..":
                    setLoadingDots("...")
                    break;
                case "...":
                    setLoadingDots("")
                    break;
                default:
                    break;
            }
        }, 200);

        return () => clearInterval(interval);
    }, [loadingDots])

    return(
        <div id="loading">
            <div className="loading-content">
                <img className="loading-content" src="/img/loading.gif"></img>
                <p className="loading-content">Loading{loadingDots}</p>
            </div>
        </div>
    )
}

export default LoadingScreen;