import "./css/404.css"
import { useNavigate } from "react-router-dom";

const NotFound = () =>{
    const navigate = useNavigate();

    const goBack = () =>{
        navigate("/home")
    }

    return(
        <>
            <div className="error" > 
                <div className="error-content">
                    <img className="error" src="/img/angel.jpg"></img>
                    <h1 className="error">Are you lost?</h1>
                    <p className="error">
                        Don't worry... everyone gets lost every once in a while.
                        What do you say... <u style={{cursor: "pointer"}} onClick={goBack}>Take my hand</u> and let me guide
                        you. I'll take you somewhere special... you may lose yourself. But it's just part of 
                        the process. 
                        <br></br>    
                        <br></br>
                        The process of <u style={{fontFamily: "digital"}}>DIGITALIZATION</u>.

                        <br></br>
                        <br></br>
                        <div style={{fontFamily: "digital"}}>404</div>
                    </p>
                </div>
            </div>
        </>
    )
}

export default NotFound;