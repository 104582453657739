// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.error{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    background-color: black;
}

div.error-content{
    position: absolute; 
    right: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;

    width: 968px;
    height: 708px;

    text-align: center;
}

img.error{
    position: absolute;
    left: 0px;
    top: 60px;
}

h1.error{
    position: relative;
    font-family: "gothic";
    color: white;

    top: 200px;
    left: 260px;
}

p.error{
    font-family: "pixel";
    font-size: 22px;
    color: white;
    position: absolute;
    top: 260px;
    left: 540px;
    text-align: left;

    word-break: break-word; /* Break words if necessary */
    overflow-wrap: break-word; /* Ensure wrapping for long text */
}`, "",{"version":3,"sources":["webpack://./src/css/404.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,WAAW;IACX,YAAY;IACZ,QAAQ;IACR,SAAS;IACT,uBAAuB;AAC3B;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,SAAS;IACT,gCAAgC;IAChC,QAAQ;;IAER,YAAY;IACZ,aAAa;;IAEb,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,SAAS;AACb;;AAEA;IACI,kBAAkB;IAClB,qBAAqB;IACrB,YAAY;;IAEZ,UAAU;IACV,WAAW;AACf;;AAEA;IACI,oBAAoB;IACpB,eAAe;IACf,YAAY;IACZ,kBAAkB;IAClB,UAAU;IACV,WAAW;IACX,gBAAgB;;IAEhB,sBAAsB,EAAE,6BAA6B;IACrD,yBAAyB,EAAE,kCAAkC;AACjE","sourcesContent":["div.error{\n    position: fixed;\n    width: 100%;\n    height: 100%;\n    top: 0px;\n    left: 0px;\n    background-color: black;\n}\n\ndiv.error-content{\n    position: absolute; \n    right: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    top: 50%;\n\n    width: 968px;\n    height: 708px;\n\n    text-align: center;\n}\n\nimg.error{\n    position: absolute;\n    left: 0px;\n    top: 60px;\n}\n\nh1.error{\n    position: relative;\n    font-family: \"gothic\";\n    color: white;\n\n    top: 200px;\n    left: 260px;\n}\n\np.error{\n    font-family: \"pixel\";\n    font-size: 22px;\n    color: white;\n    position: absolute;\n    top: 260px;\n    left: 540px;\n    text-align: left;\n\n    word-break: break-word; /* Break words if necessary */\n    overflow-wrap: break-word; /* Ensure wrapping for long text */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
