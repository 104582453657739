import "./css/construction.css"
import {useState, useEffect} from "react"
import { useNavigate } from 'react-router-dom';


const Construction = () =>{
    const [selection, setSelection] = useState(0)
    const [madLevel, setMadLevel] = useState(0)

    const navigate = useNavigate();

    useEffect(() => {
        const handleKeyUp = (event) => {
            if (event.key === "ArrowUp" || event.key === "ArrowDown") {
                if(selection == 1){
                    setSelection(0)
                }
                else{
                    setSelection(1)
                }
            }

            if(event.key === "Enter"){
                if(selection == 1){
                    setMadLevel((prev) => {return prev += 1})
                }
                else{
                    navigate("/home")
                }
            }
        };

        window.addEventListener("keydown", handleKeyUp);

        return () => {
            window.removeEventListener("keydown", handleKeyUp);
        };
    }, [selection])

    const Selection = () => {
        switch (selection) {
            case 1:
                return(
                    <div>
                        YES {"<"}
                        <br></br>
                        NO
                    </div>
                ) 
        
            case 0:
                return(
                    <div>
                        YES 
                        <br></br>
                        NO {"<"}
                    </div>
                ) 
        }
    }

    const MadMessages = () =>{
        switch (madLevel) {
            case 1:
                return(
                    <div>
                        𝚆𝙷𝙰𝚃 𝙳𝙾 𝚈𝙾𝚄 𝙼𝙴𝙰𝙽
                    </div>
                ) 
            case 2:
                return(
                    <div>
                        𝖄𝕺𝖀 𝕾𝕿𝖀𝕮𝕶
                    </div>
                )

            case 3:
                return(
                    <div>
                        ꟼOTꙄ
                    </div>
                )

            case 4:
                return(
                    <div>
                        ᴵ ˢᴬᴵᴰ ˢᵀᴼᴾ
                    </div>
                )

            case 5:
                return(
                    <div>
                        чσυ nєνєя gσιng вαcк
                    </div>
                )
            case 6:
                return(
                    <div>
                        N҉E҉V҉E҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉
                        <br></br>
                        R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉
                        <br></br>
                        R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉
                        <br></br>
                        R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉
                        <br></br>
                        R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉
                        <br></br>
                        R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉
                        <br></br>
                        R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉
                        <br></br>
                        R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉
                        <br></br>
                        R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉
                        <br></br>
                        R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉
                        <br></br>
                        R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉
                        <br></br>
                        R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉
                        <br></br>
                        R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉
                        <br></br>
                        R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉
                        <br></br>
                        R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉
                        <br></br>
                        R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉R҉
                    </div>
                )
            case 7:
                navigate("/home")
                break;
            case 8:
                break;
        }
    }

    return(
        <>
            <div id="construction">
                <div className="construction-content">
                    <img className="construction-content" src="/img/escape.jpg"></img>
                    <p className="construction-content">
                        Silly you...
                        <br></br> 
                        You have arrived to place still under construction.
                        <br></br>
                        <br></br>
                        Do you want to escape the digital space?
                        <br></br>
                        <br></br>
                        <Selection></Selection> 
                        <br></br>
                        <MadMessages></MadMessages>
                    </p>
                </div>
            </div>
        </>
    )
}
export default Construction;